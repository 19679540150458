import { gql } from "../../__generated__/gql";

gql(`
  fragment ColorFields on ThemeColorPreset {
    id
    name
    value
    factory
  }
`);

gql(`
  fragment ButtonFields on ThemeButton {
    _id
    padding {
      vertical
      horizontal
    }
    backgroundColor {
      default {
        ...ColorFields
      }
      active {
        ...ColorFields
      }
      hover {
        ...ColorFields
      }
    }
    border {
      color {
        default {
          ...ColorFields
        }
        active {
          ...ColorFields
        }
        hover {
          ...ColorFields
        }
      }
      width
      radius
      style
    }
  }
`);

gql(`
  fragment ThemeV1Fields on ThemeV1 {
    __typename
    _id
    id
    title
    version
    generation
    scss
    css
    active
    updatedAt
    isFavorite

    colours {
      primary
      secondary
      areas {
        name
        value
        preset
      }
    }
    fontSizes {
      desktop {
        name
        label
        value
      }
      mobile {
        name
        label
        value
      }
    }
    miscs {
      name
      label
      value
    }
    spacings {
      desktop {
        name
        label
        value
      }
      mobile {
        name
        label
        value
      }
    }
  }
`);

gql(`
  fragment ThemeV2Fields on ThemeV2 {
    __typename
    _id
    id
    title
    version
    generation
    scss
    css
    js
    tags
    active
    fontFaces
    appRevision
    updatedAt
    isFavorite
    browserEntry {
      title
      pricing {
        value
        currency
        paymentRequired
      }
      payment {
        paid
        invoiceId
      }
    }
    revisions {
      id
      date
      message
    }

    revisionAvailable
    styles {
      _id
      title
      selectors
      weight
      letterSpacing
      lineHeight
      textAlign
      textTransform
      textDecoration
      style
      css
      states {
        default
        visited
        hover
        active
      }
      color {
        id
        name
        value
      }
      family {
        title
        ref
        family
      }
      sizes {
        desktop
        mobile
      }
    }
    player {
      backgroundColor {
        ...ColorFields
      }
      waveformColor {
        ...ColorFields
      }
      progressColor {
        ...ColorFields
      }
      cursorColor {
        ...ColorFields
      }
      cursorWidth
      barRadius
      barWidth
      barGap
    }
    colors {
      presets {
        ...ColorFields
      }
    }
    files {
      content
      path
      hidden
      readOnly
      active
    }
    pages {
      item {
        layout {
          id
          title
        }
      }
    }
    icons {
      format
      title
      fontUri
      ref
      family
    }
    settings {
      maxWidth
      mobileBreakpoint
      borderRadius
      header {
        backgroundColor {
          ...ColorFields
        }
        logoWidth {
          mobile
          tablet
          desktop
        }
        sticky
        layout {
          id
          title
        }
      }
      carousel {
        transitionType
        transitionSpeed
        speed
        autoplay
        dots
      }
      superHeader {
        backgroundColor {
          ...ColorFields
        }
        display
      }
      navbar {
        layout {
          id
          title
        }
      }
      footer {
        backgroundColor {
          ...ColorFields
        }
        layout {
          id
          title
        }
      }
      layout {
        id
        title
      }
      backgroundColor {
        id
        name
        value
      }
      collectionColumns {
        mobile
        tablet
        desktop
      }
      gutter {
        mobile
        tablet
        desktop
      }
    }
    buttons {
      primary {
        ...ButtonFields
      }
      buy {
        ...ButtonFields
      }
    }
  }
`);

gql(`
  fragment ThemeBrowserEntryFields on ThemeBrowserEntry {
    _id
    configRef
    themeRef
    createdAt
    description
    previewUrl
    title
    credits
    published
    updatedAt
    bannerImages {
      _id
      uri
    }
    pricing {
      value
      currency
      paymentRequired
    }
  }
`);

export const POST_THEME_STYLE_UPDATE = gql(`
  mutation themeStyleUpdate($themeRef: ID!, $themeStyleUpdateInput: ThemeStyleUpdateInput!, $version: Int) {
    themeStyleUpdate(themeRef: $themeRef, version: $version, themeStyleUpdateInput: $themeStyleUpdateInput) {
      theme {
        ... on ThemeV2 {
          ...ThemeV2Fields
        }
      }
      style {
        _id
        title
        selectors
        weight
        style
        color {
          id
          name
          value
        }
        css
        family {
          title
          ref
          family
        }
        sizes {
          desktop
          mobile
        }
      }
    }
  }
`);

export const GET_THEME_BROWSER_ENTRIES = gql(`
  query themeBrowserEntries($tags: [String]) {
    themeBrowserEntries(tags: $tags) {
      tags
      themes {
        ...ThemeBrowserEntryFields
      }
      pagination {
        count
        page
        pages
      }
    }
}`);

export const GET_THEME_BROWSER_ENTRY = gql(`
  query themeBrowserEntry($themeBrowserEntryRef: ID!) {
    themeBrowserEntry(themeBrowserEntryRef: $themeBrowserEntryRef) {
      ...ThemeBrowserEntryFields
    }
}`);

export const GET_THEME = gql(`
  query theme($themeRef: ID) {
    theme(themeRef: $themeRef) {
      ... on ThemeV1 {
        ...ThemeV1Fields
      }
      ... on ThemeV2 {
        ...ThemeV2Fields
      }
    }
  }
`);

export const GET_THEMES = gql(`
  query themes {
    themes {
      __typename
      ... on ThemeV1 {
        _id
        id
        active
        title
        version
        generation
        preview
        updatedAt
        createdAt
        isFavorite
      }
      ... on ThemeV2 {
        _id
        id
        active
        title
        preview
        version
        generation
        appRevision
        updatedAt
        createdAt
        isFavorite
        browserEntry {
          title
          pricing {
            value
            currency
            paymentRequired
          }
          payment {
            paid
          }
        }
      }
    }
  }
`);

export const POST_THEME_UPDATE = gql(`
  mutation themeUpdate($themeRef: ID, $version: Int, $scss: String, $js: String, $tags: [String!], $spacings: JSON, $colors: JSON, $colours: JSON, $miscs: JSON, $title: String, $settings: JSON, $pages: ThemeUpdatePagesInput, $buttons: ThemeUpdateButtonsInput, $player: ThemeUpdatePlayerInput, $icons: ThemeUpdateIconsInput) {
    themeUpdate(themeRef: $themeRef, version: $version, scss: $scss, js: $js, tags: $tags, spacings: $spacings, colors: $colors, colours: $colours, miscs: $miscs, title: $title, settings: $settings, pages: $pages, buttons: $buttons, player: $player, icons: $icons) {
      theme {
        ... on ThemeV1 {
          ...ThemeV1Fields
        }
        ... on ThemeV2 {
          ...ThemeV2Fields
        }
      }
    }
  }
`);

export const POST_THEME_COMPILE_SASS = gql(`
  mutation themeSassCompile($sass: String!) {
    themeSassCompile(sass: $sass)   
  }
`);

export const POST_THEME_FAVORITE = gql(`
  mutation themeFavorite($themeRef: ID!, $status: Boolean!) {
    themeFavorite(themeRef: $themeRef, status: $status)    {
      ... on ThemeV1 {
          ...ThemeV1Fields
        }
        ... on ThemeV2 {
          ...ThemeV2Fields
        }
    }
  }
`);

export const POST_THEME_UPGRADE = gql(`
  mutation themeUpgrade($themeRef: ID!, $revision: Int!) {
    themeUpgrade(themeRef: $themeRef, revision: $revision) {
      ... on ThemeV2 {
        ...ThemeV2Fields
      }
    }
  }
`);

export const POST_THEME_CREATE = gql(`
  mutation themeCreate($title: String, $themeRef: ID, $themeBrowserEntryRef: ID) {
    themeCreate(title: $title, themeRef: $themeRef, themeBrowserEntryRef: $themeBrowserEntryRef) {
      ... on ThemeV2 {
        ...ThemeV2Fields
      }
    }
  }
`);

export const POST_THEME_DELETE = gql(`
  mutation themeDelete($themeRef: ID!, $version: Int!) {
    themeDelete(themeRef: $themeRef, version: $version)
  }
`);

export const POST_THEME_ACTIVATE = gql(`
  mutation themeActivate($themeRef: ID!, $version: Int!) {
    themeActivate(themeRef: $themeRef, version: $version) {
      theme {
        ... on ThemeV1 {
          ...ThemeV1Fields
        }
        ... on ThemeV2 {
          ...ThemeV2Fields
        }
      }
      config {
        ...ConfigFields
      }
    }
  }
`);

export const POST_THEME_STYLE_DELETE = gql(`
  mutation themeStyleDelete($themeRef: ID!, $styleRef: ID!, $version: Int!) {
    themeStyleDelete(themeRef: $themeRef, styleRef: $styleRef, version: $version) {
      ... on ThemeV2 {
        ...ThemeV2Fields
      }
    }
  }
`);

export const POST_THEME_PAYMENT = gql(`
  mutation themePayment($themeRef: ID!) {
    themePayment(themeRef: $themeRef) {
      theme {
         ... on ThemeV2 {
          ...ThemeV2Fields
        }
      }
      config {
        ...ConfigFields
      }
    }
  }
`);
